var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "public-holidays-wrapper" },
    _vm._l(_vm.holidays, function (holiday, indx) {
      return _c(
        "div",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltip(holiday),
              expression: "tooltip(holiday)",
            },
          ],
          key: indx,
          staticClass: "holiday-row",
        },
        [
          _c("p", { staticClass: "holiday-date" }, [
            _vm._v(_vm._s(holiday.date)),
          ]),
          _c("p", { staticClass: "holiday-name" }, [
            _vm._v(_vm._s(holiday.name)),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }